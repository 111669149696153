import { createAction, props } from "@ngrx/store";
import { ProjectItem } from "../../data-services/models/project.models";
import { CultureItem } from "../../data-services/models/language.models";
import { UserFilterItem } from "../../data-services/models/filter.models";

export const loadProject = createAction(
    '[Project] loadProject',
    props<{ projectId: string, query: { culture: string, flatHierarchies: boolean }}>()
)

export const loadProjectSuccess = createAction(
    '[Project] loadProjectSuccess',
    props<{ project: ProjectItem, filters: UserFilterItem[] }>()
)

// load project cultures
export const loadProjectCultures = createAction(
    '[Project] loadProjectCultures',
    props<{ projectId: string }>()
)

export const loadProjectCulturesSuccess = createAction(
    '[Project] loadProjectCulturesSuccess',
    props<{ cultures: CultureItem[] }>()
)

// determine project culture
export const determineProjectCulture = createAction(
    '[Project] determineProjectCulture',
    props<{ projectId: string, cultures: CultureItem[] }>()
)

export const determineProjectCultureSuccess = createAction(
    '[Project] determineProjectCultureSuccess',
    props<{ projectId: string, culture: CultureItem }>()
)

export const determineProjectCultureFailed = createAction(
    '[Project] determineProjectCultureFailed',
)

// reset user switch and preview mode
export const resetModes = createAction(
    '[Project] resetModes',
)