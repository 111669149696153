import { Observable } from 'rxjs/internal/Observable';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AnonymityInfo } from '../models/anonymity-check.models';
import { AnonymityCheckResult, BasicQuery, SurveyQuery } from '../models/misc';

@Injectable()
export class AnonymityCheckService {

  constructor(private http: HttpClient) { }

  public check = (query: SurveyQuery) => {
    return this.http.post<AnonymityCheckResult>(`${environment.apiUrl}/api/${query.projectId}/anonymitycheck`, query);
  }

  public checkProjectAnonymity = (query: BasicQuery): Observable<Array<AnonymityInfo>> => {
    return this.http.post<Array<AnonymityInfo>>(`${environment.apiUrl}/api/${query.projectId}/anonymitycheck/project`, query);
  }
}
