export const environment = {
  production: false,
  apiUrl: 'https://test-waves-dashboard-api-v2.metricality.io',
  authSettings: {
    authority: 'https://test-login.metricality.io',
    redirectUrl: window.location.origin,
    postLogoutRedirectUri: window.location.origin,
    clientId: 'Waves.Dashboard.Client',
    scope: 'openid profile waves-dashboard-api-scope offline_access', // 'openid profile offline_access ' + your scopes
    responseType: 'code',
    silentRenew: true,
    useRefreshToken: true,
    ignoreNonceAfterRefresh: true, // this is required if the id_token is not returned
    triggerRefreshWhenIdTokenExpired: false,
    renewTimeBeforeTokenExpiresInSeconds: 5,
    secureRoutes: ['https://test-waves-dashboard-api-v2.metricality.io/'],
  },
  defaultUICulture: 'en-US',
};
